import { gql } from '@apollo/client';

import {
  addCategoryRecipe,
  addHowtosRecipe,
  addIngredientRecipe,
  addIngredientSectionRecipe,
  addLinkedRecipes,
  addModelRecipe,
  addModelSectionRecipe,
  addSectionRecipe,
  addStepRecipe,
  addTagsRecipe,
  addToolsRecipe,
  addVideo,
  addYieldType,
  createCategory,
  createFraction,
  createHowto,
  createIngredient,
  createIngredientCategory,
  createIngredientDoneness,
  createIngredientSection,
  createRecipe,
  createSection,
  createTag,
  createTool,
  createUnit,
  removeCategoryRecipe,
  removeFraction,
  removeHowtosRecipe,
  removeIngredient,
  removeIngredientDoneness,
  removeIngredientRecipe,
  removeIngredientSectionRecipe,
  removeLinkedRecipes,
  removeModelRecipe,
  removeModelSectionRecipe,
  removeSectionRecipe,
  removeStepRecipe,
  removeTagsRecipe,
  removeToolsRecipe,
  removeUnit,
  replaceIngredient,
  replaceStepRecipe,
  updateFraction,
  updateIngredient,
  updateIngredientDoneness,
  updateIngredientRecipe,
  updateIngredientSectionRecipe,
  updateRecipe,
  updateSectionRecipe,
  updateStepRecipe,
  updateUnit,
  updateVideo,
  versionRecipe,
  createReport,
  addTicketAction,
  addProfilesRecipe,
  removeProfileRecipe,
  addSectionRecipeAction,
  updateSectionRecipeAction,
  removeSectionRecipeAction,
  addSectionRecipeCondition,
  updateSectionRecipeCondition,
  removeSectionRecipeCondition,
  addStepAction,
  updateStepAction,
  removeStepAction,
  addStepCondition,
  updateStepCondition,
  removeStepCondition,
} from '../graphql/mutations';
import {
  getAllActions,
  getAllActionsModels,
  getAllActionsProperties,
  getAllCategories,
  getAllFractions,
  getAllHowtos,
  getAllIngredientCategories,
  getAllIngredientSections,
  getAllIngredients,
  getAllModels,
  getAllPropertiesChoices,
  getAllRecipeDevelopers,
  getAllRecipes,
  getAllSections,
  getAllTags,
  getAllTools,
  getAllUnits,
  getAllVideoCategories,
  getAllVideos,
  getAllYieldTypes,
  getIngredient,
  getRecipe,
  getVideo,
  getAllAppliances,
  getPropertiesByCode,
  getNotificationCategories,
  getTemperatureGuides,
  getTickets,
  getTicket,
  getRelatedTickets,
  getRecipes,
  getSectionRecipeModels,
  getProfiles,
  searchRecipes,
  searchIngredients,
  searchIngredientsSuggest,
  getModels,
  getSections,
  getConditionsModels,
  getPropertiesChoices,
  getActionsModels,
  getActionsProperties,
  getActions
} from '../graphql/queries';

// Query
export const GET_ALL_APPLIANCES = gql`
  ${getAllAppliances}
`;
export const GET_ALL_ACTIONS = gql`
  ${getAllActions}
`;
export const GET_ALL_ACTIONS_PROPERTIES = gql`
  ${getAllActionsProperties}
`;
export const GET_ALL_CATEGORIES = gql`
  ${getAllCategories}
`;
export const GET_ALL_ACTIONS_MODELS = gql`
  ${getAllActionsModels}
`;
export const GET_ALL_MODELS = gql`
  ${getAllModels}
`; // deprecated
export const GET_ALL_FRACTIONS = gql`
  ${getAllFractions}
`;
export const GET_ALL_HOWTOS = gql`
  ${getAllHowtos}
`;
export const GET_ALL_INGREDIENTS = gql`
  ${getAllIngredients}
`;
export const GET_ALL_INGREDIENT_CATEGORIES = gql`
  ${getAllIngredientCategories}
`;
export const GET_ALL_INGREDIENT_SECTIONS = gql`
  ${getAllIngredientSections}
`;
export const GET_ALL_PROPERTIES_CHOICES = gql`
  ${getAllPropertiesChoices}
`;
export const GET_PROPERTIES_BY_CODE = gql`
  ${getPropertiesByCode}
`;
export const GET_ALL_RECIPES = gql`
  ${getAllRecipes}
`;
export const GET_RECIPES = gql`
  ${getRecipes}
`;
export const GET_ALL_RECIPE_DEVELOPERS = gql`
  ${getAllRecipeDevelopers}
`;
export const GET_ALL_SECTIONS = gql`
  ${getAllSections}
`;
export const GET_ALL_TAGS = gql`
  ${getAllTags}
`;
export const GET_ALL_TOOLS = gql`
  ${getAllTools}
`;
export const GET_ALL_UNITS = gql`
  ${getAllUnits}
`;
export const GET_ALL_VIDEOS = gql`
  ${getAllVideos}
`;
export const GET_ALL_VIDEO_CATEGORIES = gql`
  ${getAllVideoCategories}
`;
export const GET_ALL_YIELD_TYPES = gql`
  ${getAllYieldTypes}
`;
export const GET_INGREDIENT = gql`
  ${getIngredient}
`;
export const GET_RECIPE = gql`
  ${getRecipe}
`;
export const GET_VIDEO = gql`
  ${getVideo}
`;
export const GET_NOTIFICATION_CATEGORIES = gql`
  ${getNotificationCategories}
`;
export const GET_TEMPERATURE_GUIDES = gql`
  ${getTemperatureGuides}
`;
export const SEARCH_RECIPES = gql`
  ${searchRecipes}
`;

// Mutation
export const ADD_CATEGORY_RECIPE = gql`
  ${addCategoryRecipe}
`;
export const ADD_HOWTOS_RECIPE = gql`
  ${addHowtosRecipe}
`;
export const ADD_INGREDIENT_RECIPE = gql`
  ${addIngredientRecipe}
`;
export const ADD_INGREDIENT_SECTION_RECIPE = gql`
  ${addIngredientSectionRecipe}
`;
export const ADD_INGREDIENT_DONENESS = gql`
  ${createIngredientDoneness}
`;
export const REMOVE_INGREDIENT_DONENESS = gql`
  ${removeIngredientDoneness}
`;
export const UPDATE_INGREDIENT_DONENESS = gql`
  ${updateIngredientDoneness}
`;
export const ADD_LINKED_RECIPES = gql`
  ${addLinkedRecipes}
`;
export const ADD_SECTION_RECIPE = gql`
  ${addSectionRecipe}
`;
export const ADD_STEP_RECIPE = gql`
  ${addStepRecipe}
`;
export const ADD_TAGS_RECIPE = gql`
  ${addTagsRecipe}
`;
export const ADD_TOOLS_RECIPE = gql`
  ${addToolsRecipe}
`;
export const ADD_VIDEO = gql`
  ${addVideo}
`;
export const ADD_YIELD_TYPE = gql`
  ${addYieldType}
`;
export const CREATE_CATEGORY = gql`
  ${createCategory}
`;
export const CREATE_FRACTION = gql`
  ${createFraction}
`;
export const CREATE_HOWTO = gql`
  ${createHowto}
`;
export const CREATE_INGREDIENT = gql`
  ${createIngredient}
`;
export const CREATE_INGREDIENT_CATEGORY = gql`
  ${createIngredientCategory}
`;
export const CREATE_INGREDIENT_SECTION = gql`
  ${createIngredientSection}
`;
export const CREATE_RECIPE = gql`
  ${createRecipe}
`;
export const CREATE_SECTION = gql`
  ${createSection}
`;
export const CREATE_TAG = gql`
  ${createTag}
`;
export const CREATE_TOOL = gql`
  ${createTool}
`;
export const CREATE_UNIT = gql`
  ${createUnit}
`;
export const REMOVE_CATEGORY_RECIPE = gql`
  ${removeCategoryRecipe}
`;
export const REMOVE_FRACTION = gql`
  ${removeFraction}
`;
export const REMOVE_HOWTOS_RECIPE = gql`
  ${removeHowtosRecipe}
`;
export const REMOVE_INGREDIENT = gql`
  ${removeIngredient}
`;
export const REMOVE_INGREDIENT_RECIPE = gql`
  ${removeIngredientRecipe}
`;
export const REMOVE_INGREDIENT_SECTION_RECIPE = gql`
  ${removeIngredientSectionRecipe}
`;
export const REMOVE_LINKED_RECIPES = gql`
  ${removeLinkedRecipes}
`;
export const REMOVE_SECTION_RECIPE = gql`
  ${removeSectionRecipe}
`;
export const REMOVE_STEP_RECIPE = gql`
  ${removeStepRecipe}
`;
export const REMOVE_TAGS_RECIPE = gql`
  ${removeTagsRecipe}
`;
export const REMOVE_TOOLS_RECIPE = gql`
  ${removeToolsRecipe}
`;
export const REMOVE_UNIT = gql`
  ${removeUnit}
`;
export const REPLACE_INGREDIENT = gql`
  ${replaceIngredient}
`;
export const REPLACE_STEP_RECIPE = gql`
  ${replaceStepRecipe}
`;
export const UPDATE_FRACTION = gql`
  ${updateFraction}
`;
export const UPDATE_INGREDIENT = gql`
  ${updateIngredient}
`;
export const UPDATE_INGREDIENT_RECIPE = gql`
  ${updateIngredientRecipe}
`;
export const UPDATE_INGREDIENT_SECTION_RECIPE = gql`
  ${updateIngredientSectionRecipe}
`;
export const UPDATE_RECIPE = gql`
  ${updateRecipe}
`;
export const UPDATE_SECTION_RECIPE = gql`
  ${updateSectionRecipe}
`;
export const UPDATE_STEP_RECIPE = gql`
  ${updateStepRecipe}
`;
export const UPDATE_UNIT = gql`
  ${updateUnit}
`;
export const UPDATE_VIDEO = gql`
  ${updateVideo}
`;
export const VERSION_RECIPE = gql`
  ${versionRecipe}
`;

export const CREATE_REPORT = gql`
  ${createReport}
`;
export const ADD_TICKET_ACTION = gql`
  ${addTicketAction}
`;

export const GET_TICKETS = gql`
  ${getTickets}
`;
export const GET_TICKET = gql`
  ${getTicket}
`;
export const GET_RELATED_TICKETS = gql`
  ${getRelatedTickets}
`;
export const ADD_MODEL_RECIPE = gql`
  ${addModelRecipe}
`;
export const REMOVE_MODEL_RECIPE = gql`
  ${removeModelRecipe}
`;
export const GET_SECTION_RECIPE_MODELS = gql`
  ${getSectionRecipeModels}
`;
export const ADD_MODEL_SECTION_RECIPE = gql`
  ${addModelSectionRecipe}
`;
export const REMOVE_MODEL_SECTION_RECIPE = gql`
  ${removeModelSectionRecipe}
`;

export const GET_PROFILES = gql`
  ${getProfiles}
`;

export const ADD_PROFILES_RECIPE = gql`
  ${addProfilesRecipe}
`;

export const REMOVE_PROFILES_RECIPE = gql`
  ${removeProfileRecipe}
`;

export const SEARCH_INGREDIENTS = gql`
  ${searchIngredients}
`;

export const SEARCH_INGREDIENTS_SUGGEST = gql`
  ${searchIngredientsSuggest}
`;

export const GET_MODELS = gql`
  ${getModels}
`;

export const GET_SECTIONS = gql`
  ${getSections}
`;

export const GET_CONDITIONS_MODELS = gql`
  ${getConditionsModels}
`;

export const GET_PROPERTIES_CHOICES = gql`
 ${getPropertiesChoices}
`;

export const GET_ACTIONS_MODELS = gql`
  ${getActionsModels}
`;

export const GET_ACTIONS_PROPERTIES = gql`
  ${getActionsProperties}
`;

export const ADD_SECTION_RECIPE_ACTION = gql`
  ${addSectionRecipeAction}
`;

export const UPDATE_SECTION_RECIPE_ACTION = gql`
  ${updateSectionRecipeAction}
`;

export const REMOVE_SECTION_RECIPE_ACTION = gql`
  ${removeSectionRecipeAction}
`;

export const ADD_SECTION_RECIPE_CONDITION = gql`
  ${addSectionRecipeCondition}
`;

export const UPDATE_SECTION_RECIPE_CONDITION = gql`
  ${updateSectionRecipeCondition}
`;

export const REMOVE_SECTION_RECIPE_CONDITION = gql`
  ${removeSectionRecipeCondition}
`;

export const ADD_STEP_ACTION = gql`
  ${addStepAction}
`;

export const UPDATE_STEP_ACTION = gql`
  ${updateStepAction}
`;

export const REMOVE_STEP_ACTION = gql`
  ${removeStepAction}
`;

export const ADD_STEP_CONDITION = gql`
  ${addStepCondition}
`;

export const UPDATE_STEP_CONDITION = gql`
  ${updateStepCondition}
`;

export const REMOVE_STEP_CONDITION = gql`
  ${removeStepCondition}
`;

export const GET_ACTIONS = gql`
  ${getActions}
`;