import { IOptions as SanitizeIOptions } from 'sanitize-html';

import { RecipeProps } from 'src/types/recipe';

import { UiTagTypesKeys, UiTagTypes } from './types';

export * from './types';

// Recipe
export const DIFFICULTY_LEVEL = ['Advanced', 'Medium', 'Easy'];

export const DB_INT_MAX = 2147483648; // Int(11)

export const RECIPE_FORM_INITIAL_VALUES: RecipeProps = {
  typename: 'Recipe',
  recipeId: '',
  name: '',
  deviceUsed: '',
  devicesUsed: [],
  applianceId: '',
  difficultyLevel: null,
  skillLevel: '',
  description: '',
  kitchenTools: [],
  howtos: [],
  linkedRecipes: [],
  diets: [],
  categories: [],
  mealTypes: [],
  cuisines: [],
  occasions: [],
  conveniences: [],
  status: null,
  loading: false,
  activeTime: null,
  totalTime: null,
  totalLikes: null,
  updatedAt: null,
  updatedBy: null,
  yieldNumber: null,
  yieldType: null,
  coverPhoto: null,
  coverVideo: null,
  createdAt: null,
  createdBy: null,
  hasPrecookedIngredients: null,
  recipeDeveloperId: null,
  recipeNumber: null,
};

export const RECIPE_PAGE_TABS = [
  { value: 'recipe', label: 'Recipe' },
  { value: 'review', label: 'Review' },
  // { value: 'activity', label: 'Activity' }, // TODO: Uncomment when the feature is implemented
];

export const INDEPENDENT_INGREDIENTS = 'independent-ingredients';

export const PLURALIZE_TAG_TYPE: { [index in UiTagTypesKeys]: UiTagTypes } = {
  diet: 'diets',
  category: 'categories',
  convenience: 'conveniences',
  mealType: 'mealTypes',
  cuisine: 'cuisines',
  occasion: 'occasions',
};

export const SANITIZE_CONFIG: SanitizeIOptions = {
  allowedTags: ['span'],
  allowedAttributes: { span: ['class'] },
};

export const DEVICE_MODEL_TYPES = {
  APPLIANCE: 'appliance',
  ACCESSORY: 'accessory',
};

export const THING_CATEGORY_NAMES = {
  COOKER: 'cooker',
  THERMOMETER: 'thermometer',
  OVEN: 'oven',
};

export const ACTIONS_MODELS_INPUT = {
  [THING_CATEGORY_NAMES.THERMOMETER]: 'CQ60-1-HUB',
  [THING_CATEGORY_NAMES.COOKER]: 'RJ40-6-WIFI',
  [THING_CATEGORY_NAMES.OVEN]: 'CQ50',
};

export const ACTIONS_MODELS_CATEGORY = {
  SECTION: 'section',
  STEP: 'step',
};

export const CONDITIONS_CODE = {
  START_CONDITION: 'start_condition',
  AUTO_CONTINUE: 'auto_continue',
};
