import {
  snakeCase as _snakeCase,
  startCase as _startCase,
  orderBy as _orderBy,
  groupBy as _groupBy,
  flatMap as _flatMap,
  Dictionary,
} from 'lodash';

import {
  ALL_TIME_FORMATS,
  TIMER_FORMATS,
  TOAST_VARIANT,
  MINUTES_TIME_FORMATS,
  SECONDS_TIME_FORMATS,
  THING_CATEGORY_NAMES,
} from 'src/constants';
import { Step } from 'src/types/camelCaseApi';

import { delay } from '../global';
import {
  CheckForTimerInDescriptionInput,
  CheckForTimerInput,
  DragEndInput,
  GetActionDescriptionInput,
  GetStepTimerActionInput,
  GetStepTimerActionsInput,
  GetStepTitleInput,
  GetTimerListInput,
  HighlightInTextTimerInput,
  RemoveSectionInput,
  RemoveStepInput,
  SetNewItemOrderInput,
  UpdateAllStepOrdersInput,
} from './types';

export const checkForTimer = ({ value }: CheckForTimerInput) =>
  ALL_TIME_FORMATS.some((el) => value.includes(el));

export const checkForTimerInDescription = ({
  description,
}: CheckForTimerInDescriptionInput) => {
  if (description.includes('*')) {
    const splitValue = description.split('*');

    if (splitValue.length % 3 === 0) {
      return splitValue.some(
        (value: string, index: number) =>
          (index + 1) % 2 === 0 && checkForTimer({ value }),
      );
    }
  }
  return false;
};

export const highlightInTextTimer = ({
  description,
  classname,
}: HighlightInTextTimerInput) => {
  if (!description) return '';
  if (!checkForTimer({ value: description })) return description;

  const splitValue = description?.split('*');

  if (Array.isArray(splitValue)) {
    const timerHighlightedValue = splitValue.reduce((acc, value, index) => {
      if (index % 2 !== 0)
        return `${acc}<span class=${classname}>${value}</span>&#8203;`;
      return acc + value;
    }, '');

    return timerHighlightedValue;
  }
  return description;
};

export const getStepTitle = ({
  stepNumber,
  description,
  classname,
}: GetStepTitleInput) => {
  const stepPrefix = `Step ${stepNumber}`;
  const stepDescription = classname
    ? highlightInTextTimer({ description, classname })
    : description || '';

  const fullString = `${stepPrefix}: ${stepDescription}`;

  return {
    fullString,
    stepPrefix,
    stepDescription,
  };
};

const getStepTimerAction = ({
  timeInSeconds,
  index,
  timerIndex,
}: GetStepTimerActionInput) => ({
  command: 'execute_timer',
  properties: {
    timer_nr: timeInSeconds,
    index,
    inTextTimerIndex: timerIndex,
  },
});

const getTimerList = ({ description }: GetTimerListInput) => {
  const hasTimer = checkForTimerInDescription({ description });
  const timerList: Array<number> = [];

  if (hasTimer) {
    description.split('*').forEach((value, index) => {
      if (index !== 0 && index % 2 !== 0) {
        let timeInSeconds = 0;
        let trimmedValue = value.trim();

        const currentFormat: { [index: string]: string } = {};

        Object.keys(TIMER_FORMATS)
          .reverse()
          .forEach((eachFormat) => {
            TIMER_FORMATS[eachFormat].reverse().some((item) => {
              if (trimmedValue.includes(item)) {
                currentFormat[eachFormat] = item;
                return true;
              }
              return false;
            });
          });

        if (currentFormat.hourFormat) {
          const valueSplitByHour = trimmedValue.split(currentFormat.hourFormat);
          const hours = valueSplitByHour?.[0]?.replace('s', '').trim();
          timeInSeconds += hours ? parseInt(hours) * 60 * 60 : 0;
          trimmedValue = valueSplitByHour?.[1]?.trim();
        }
        if (currentFormat.minuteFormat) {
          const valueSplitByMinute = trimmedValue.split(
            currentFormat.minuteFormat,
          );
          const minutes = valueSplitByMinute?.[0]?.replace('s', '').trim();
          timeInSeconds += minutes ? parseInt(minutes) * 60 : 0;
          trimmedValue = valueSplitByMinute?.[1]?.trim();
        }
        if (currentFormat.secondFormat) {
          const valueSplitBySecond = trimmedValue.split(
            currentFormat.secondFormat,
          );
          const seconds = valueSplitBySecond?.[0]?.replace('s', '').trim();
          timeInSeconds += seconds ? parseInt(seconds) : 0;
        }
        timeInSeconds && timerList.push(timeInSeconds);
      }
    });
  }
  return {
    hasTimer,
    timerList,
  };
};

export const getStepTimerActions = ({
  description,
  stepNumber,
  thingCategoryName,
}: GetStepTimerActionsInput) => {
  // Ignore in text timer actions for oven
  if (thingCategoryName === THING_CATEGORY_NAMES.OVEN) {
    return { hasTimer: false, timerActions: [] };
  }

  const { hasTimer, timerList } = getTimerList({ description });

  const timerActions: ReturnType<typeof getStepTimerAction>[] = [];
  if (hasTimer && stepNumber) {
    timerList.forEach((timeInSeconds, index) => {
      const timerAction = getStepTimerAction({
        timeInSeconds,
        index: stepNumber - 1,
        timerIndex: index,
      });
      timeInSeconds && timerAction && timerActions.push(timerAction);
    });
  }

  return { hasTimer, timerActions };
};

export const getActionDescription = ({
  actionName,
  description,
}: GetActionDescriptionInput) =>
  description ||
  `Press "START/STOP" on the unit to start ${actionName || ''} function`;

const setNewItemOrder = <T, K extends Record<string, unknown>>({
  parents,
  children,
  parentIdKey,
  parentOrderKey,
  childOrderKey,
  sourceSectionId,
  destinationSectionId,
  sourceStepsArray,
  destinationStepsArray,
}: SetNewItemOrderInput<T, K>) => {
  const allItems: K[] = [];

  _orderBy(parents, parentOrderKey).forEach((parent) => {
    const sectionId = (parent as Record<string, unknown>)[
      parentIdKey
    ] as string;
    let sectionItems = children[sectionId] || [];

    if (sectionId === sourceSectionId && sourceStepsArray) {
      sectionItems = sourceStepsArray;
    } else if (sectionId === destinationSectionId && destinationStepsArray) {
      sectionItems = destinationStepsArray.map((step) => ({
        ...step,
        [parentIdKey]: sectionId,
      }));
    }
    allItems.push(...sectionItems);
  });

  allItems.forEach((item, index) => {
    (item as Record<string, unknown>)[childOrderKey] = index + 1;
  });

  return allItems;
};

export const handleRemoveSection = async <T extends Record<string, unknown>>({
  id,
  removeSectionRecipe,
  sectionsState,
  setSectionsState,
  updateSectionRecipe,
  sectionIdKey,
  removeMutationValue,
  setFieldValue,
}: RemoveSectionInput<T>): Promise<void> => {
  setFieldValue('loading', true);

  const { data } = await removeSectionRecipe({
    variables: {
      input: {
        [_snakeCase(sectionIdKey)]: id,
      },
    },
  });
  if (data?.[removeMutationValue]?.success) {
    const newArray = sectionsState.filter((item) => item[sectionIdKey] !== id);
    const orderedArray = newArray.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
    setSectionsState(orderedArray);
    orderedArray.forEach((item, index) => {
      updateSectionRecipe({
        variables: {
          input: {
            [_snakeCase(sectionIdKey)]: item[sectionIdKey],
            order: index + 1,
          },
        },
      });
    });
  }

  setFieldValue('loading', false);
};

export const handleRemoveStep = async <T extends Record<string, unknown>>({
  id,
  parentId,
  removeStepRecipe,
  stepsState,
  parentState,
  setStepsState,
  updateStepRecipe,
  stepIdKey,
  orderIdKey,
  parentOrderIdKey,
  parentIdKey,
  removeMutationValue,
  setFieldValue,
}: RemoveStepInput<T>): Promise<void> => {
  if (!id || !stepsState) return;

  setFieldValue('loading', true);

  const { data } = await removeStepRecipe({
    variables: {
      input: {
        [_snakeCase(stepIdKey)]: id,
      },
    },
  });
  if (data?.[removeMutationValue]?.success) {
    const filteredList = stepsState[parentId].filter(
      (el) => el[stepIdKey] !== id,
    );
    const tempStepsState: Record<string, T[]> = {
      ...stepsState,
      [parentId]: filteredList,
    };

    if (parentState && parentIdKey) {
      const allItems = setNewItemOrder({
        parents: parentState,
        children: tempStepsState,
        parentIdKey,
        parentOrderKey: parentOrderIdKey || 'order',
        childOrderKey: orderIdKey || 'order',
      });

      const nextState = _groupBy(allItems, parentIdKey);
      setStepsState(nextState);

      const updateStepPromises = allItems.map(
        ({ [stepIdKey]: stepId, [orderIdKey || 'order']: order }, i) => {
          // Temporary workaround for updated_at_UNIQUE constraint.
          return delay(150 * i).then(() =>
            updateStepRecipe({
              variables: {
                input: {
                  [_snakeCase(stepIdKey)]: stepId,
                  [_snakeCase(orderIdKey)]: order,
                },
              },
            }),
          );
        },
      );
      await Promise.allSettled(updateStepPromises);
    } else {
      Object.values(tempStepsState[parentId]).forEach((item, index) => {
        const itemOrderKey = orderIdKey ? _snakeCase(orderIdKey) : 'order';

        updateStepRecipe({
          variables: {
            input: {
              [_snakeCase(stepIdKey)]: item[stepIdKey],
              [itemOrderKey]: index + 1,
            },
          },
        });
      });
      setStepsState(tempStepsState);
    }
  }

  setFieldValue('loading', false);
};

function reorder<ItemState>(
  itemState: ItemState[],
  sourceIndex: number,
  destinationIndex: number,
  indexKey: string,
  offset = 0,
) {
  const newArray = [...itemState];
  // Moved to the same list on different position
  const movedItem = newArray.splice(sourceIndex, 1);
  newArray.splice(destinationIndex, 0, movedItem[0]);
  return newArray.map((item, index) => ({
    ...item,
    [indexKey]: offset + index + 1,
  }));
}

const getOffset = <T extends Record<string, unknown>>(
  state: Dictionary<T[]>,
  childOrderKey: string,
  parentIdKey: string,
  currentSectionId: string,
) => {
  const allItems = _orderBy(_flatMap(Object.values(state)), childOrderKey);
  let offset = 0;

  for (let i = 0; i < allItems.length; i++) {
    if (allItems[i][parentIdKey] === currentSectionId) {
      break;
    }
    offset++;
  }
  return offset;
};

export const updateAllStepOrders = async ({
  sectionsState,
  stepsState,
  setStepsState,
  updateStepRecipe,
  sectionIdKey,
  stepIdKey,
  stepOrderKey,
  excludeIds = [],
}: UpdateAllStepOrdersInput): Promise<void> => {
  const newStepsState: Dictionary<Array<Step>> = {};
  let stepNumber = 0;

  sectionsState.forEach((section) => {
    const sectionId = section[sectionIdKey] as string;

    if (excludeIds.includes(sectionId)) return;

    const stepsInSection = stepsState[sectionId];

    if (stepsInSection) {
      newStepsState[sectionId] = [];

      stepsInSection.forEach((step) => {
        stepNumber++;
        newStepsState[sectionId].push({
          ...step,
          [stepOrderKey]: stepNumber,
        });
      });

      newStepsState[sectionId].forEach((step) => {
        updateStepRecipe({
          variables: {
            input: {
              [_snakeCase(stepIdKey)]: step[stepIdKey],
              [_snakeCase(stepOrderKey)]: step[stepOrderKey],
            },
          },
        });
      });
    }
  });

  setStepsState(newStepsState);
};

export const onDragEnd = async <T, K extends Record<string, unknown>>({
  source,
  destination,
  parentState,
  childState,
  setParentState,
  setChildState,
  updateParent,
  updateChild,
  parentIdKey,
  childIdKey,
  parentType,
  childType,
  parentOrderKey,
  childOrderKey,
  enqueueSnackbar,
}: DragEndInput<T, K>) => {
  try {
    // Dropped outside the list
    if (!destination) {
      return;
    }

    // Card has not been moved
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.droppableId.includes(parentType)
    ) {
      // Section has been moved
      const orderedArray = reorder<T>(
        parentState,
        source.index,
        destination.index,
        parentOrderKey,
      );

      setParentState(orderedArray);

      const allItems = setNewItemOrder({
        parents: orderedArray,
        children: childState,
        parentIdKey,
        parentOrderKey,
        childOrderKey,
      });

      orderedArray.forEach(({ [parentIdKey]: id, [parentOrderKey]: order }) => {
        updateParent({
          variables: {
            input: {
              [_snakeCase(parentIdKey)]: id,
              [_snakeCase(parentOrderKey)]: order,
            },
          },
        });
      });

      const nextChildState = _groupBy(allItems, parentIdKey);

      setChildState(nextChildState);

      const updateStepPromises = allItems.map(
        ({ [childIdKey]: id, [childOrderKey]: order }, i) => {
          // Temporary workaround for updated_at_UNIQUE constraint.
          return delay(150 * i).then(() =>
            updateChild({
              variables: {
                input: {
                  [_snakeCase(childIdKey)]: id,
                  [_snakeCase(childOrderKey)]: order,
                },
              },
            }),
          );
        },
      );
      await Promise.allSettled(updateStepPromises);
    } else if (
      source.droppableId === destination.droppableId &&
      source.droppableId.includes(childType)
    ) {
      // Moved within the same list
      const currentSectionId = source.droppableId.replace(`${childType}s-`, '');

      const offset = getOffset(
        childState,
        childOrderKey,
        parentIdKey,
        currentSectionId,
      );

      const orderedArray = reorder(
        childState[currentSectionId],
        source.index,
        destination.index,
        childOrderKey,
        offset,
      );
      setChildState({ ...childState, [currentSectionId]: [...orderedArray] });
      orderedArray.forEach(({ [childIdKey]: id, [childOrderKey]: order }) => {
        updateChild({
          variables: {
            input: {
              [_snakeCase(childIdKey)]: id,
              [_snakeCase(childOrderKey)]: order,
            },
          },
        });
      });
    } else if (source.droppableId.includes(childType)) {
      // Moved to another list
      const sourceSectionId = source.droppableId.replace(`${childType}s-`, '');
      const destinationSectionId = destination.droppableId.replace(
        `${childType}s-`,
        '',
      );

      const sourceStepsArray = [...(childState[sourceSectionId] || [])];
      const destinationStepsArray = [
        ...(childState[destinationSectionId] || []),
      ];

      const removedItem = sourceStepsArray.splice(source.index, 1);
      destinationStepsArray.splice(destination.index, 0, removedItem[0]);

      const allItems = setNewItemOrder({
        parents: parentState,
        children: childState,
        parentIdKey,
        parentOrderKey,
        childOrderKey,
        sourceSectionId,
        destinationSectionId,
        sourceStepsArray,
        destinationStepsArray,
      });

      const nextChildState = _groupBy(allItems, parentIdKey);

      setChildState(nextChildState);

      const updateStepPromises = allItems.map(
        (
          { [childIdKey]: id, [childOrderKey]: order, [parentIdKey]: parentId },
          i,
        ) => {
          // Temporary workaround for updated_at_UNIQUE constraint.
          return delay(150 * i).then(() =>
            updateChild({
              variables: {
                input: {
                  [_snakeCase(childIdKey)]: id,
                  [_snakeCase(childOrderKey)]: order,
                  [_snakeCase(parentIdKey)]: parentId,
                },
              },
            }),
          );
        },
      );
      await Promise.allSettled(updateStepPromises);
    }
  } catch (error) {
    enqueueSnackbar((error as Error)?.message, TOAST_VARIANT.ERROR);
  }
};
